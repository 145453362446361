//
// Nomadix SCSS theming CSS variables generator
//
//
$ez-schemas: ();

//
// Single-variant CSS color variable map.
//
// This map identifies color variables that are to be generated WITHOUT any additional scale-series.
// In each case the key is a CSS variable name, and the value is the color value to be assigned to that
// variable.
//
// Each entry will produce one color variable
// e.g. --name: #rrggbb;
//
//
$ez-color-vars-plain: (
  --ez-placeholder: $ez-placeholder-color,
  --ez-dropdown-bg: $dropdown-bg,
  --ez-dropdown-border: $dropdown-border-color,
  --bs-gray: $gray-600,
  --bs-gray-100: $gray-100,
  --bs-gray-200: $gray-200,
  --bs-gray-300: $gray-300,
  --bs-gray-400: $gray-400,
  --bs-gray-500: $gray-500,
  --bs-gray-600: $gray-600,
  --bs-gray-700: $gray-700,
  --bs-gray-800: $gray-800,
  --bs-gray-900: $gray-900,
  --ez-gamut-1-tx: map-get($ez-gamut-1, text),
  --ez-gamut-1-dk: map-get($ez-gamut-1, dk),
  --ez-gamut-1-lt: map-get($ez-gamut-1, lt),
  --ez-gamut-2-tx: map-get($ez-gamut-2, text),
  --ez-gamut-2-dk: map-get($ez-gamut-2, dk),
  --ez-gamut-2-lt: map-get($ez-gamut-2, lt),
  --ez-gamut-3-tx: map-get($ez-gamut-3, text),
  --ez-gamut-3-dk: map-get($ez-gamut-3, dk),
  --ez-gamut-3-lt: map-get($ez-gamut-3, lt),
  --ez-gamut-4-tx: map-get($ez-gamut-4, text),
  --ez-gamut-4-dk: map-get($ez-gamut-4, dk),
  --ez-gamut-4-lt: map-get($ez-gamut-4, lt),
  --ez-gamut-5-tx: map-get($ez-gamut-5, text),
  --ez-gamut-5-dk: map-get($ez-gamut-5, dk),
  --ez-gamut-5-lt: map-get($ez-gamut-5, lt),

  --ez-in-service-tx: map-get($ez-in-service, text),
  --ez-in-service-dk: map-get($ez-in-service, dk),
  --ez-in-service-lt: map-get($ez-in-service, lt),
  --ez-blocked-tx: map-get($ez-blocked, text),
  --ez-blocked-dk: map-get($ez-blocked, dk),
  --ez-blocked-lt: map-get($ez-blocked, lt),
  --ez-start-date-pending-tx: map-get($ez-start-date-pending, text),
  --ez-start-date-pending-dk: map-get($ez-start-date-pending, dk),
  --ez-start-date-pending-lt: map-get($ez-start-date-pending, lt),
  --ez-consent-pending-tx: map-get($ez-consent-pending, text),
  --ez-consent-pending-dk: map-get($ez-consent-pending, dk),
  --ez-consent-pending-lt: map-get($ez-consent-pending, lt),
  --ez-new-policy-avail-tx: map-get($ez-new-policy-avail, text),
  --ez-new-policy-avail-dk: map-get($ez-new-policy-avail, dk),
  --ez-new-policy-avail-lt: map-get($ez-new-policy-avail, lt),
  --ez-error-status-tx: map-get($ez-error-status, text),
  --ez-error-status-dk: map-get($ez-error-status, dk),
  --ez-error-status-lt: map-get($ez-error-status, lt),
  --ez-plan-pending-tx: map-get($ez-plan-pending, text),
  --ez-plan-pending-dk: map-get($ez-plan-pending, dk),
  --ez-plan-pending-lt: map-get($ez-plan-pending, lt),
  --ez-paid-pending-tx: map-get($ez-paid-pending, text),
  --ez-paid-pending-dk: map-get($ez-paid-pending, dk),
  --ez-paid-pending-lt: map-get($ez-paid-pending, lt),
  --ez-expired-license-tx: map-get($ez-expired-license, text),
  --ez-expired-license-dk: map-get($ez-expired-license, dk),
  --ez-expired-license-lt: map-get($ez-expired-license, lt),
  --ez-unanticipated-tx: map-get($ez-unanticipated, text),
  --ez-unanticipated-dk: map-get($ez-unanticipated, dk),
  --ez-unanticipated-lt: map-get($ez-unanticipated, lt),
  --ez-expired-tx: map-get($ez-expired, text),
  --ez-expired-dk: map-get($ez-expired, dk),
  --ez-expired-lt: map-get($ez-expired, lt),


  --ez-dev-ok-tx: map-get($ez-dev-ok, text),
  --ez-dev-ok-dk: map-get($ez-dev-ok, dk),
  --ez-dev-ok-lt: map-get($ez-dev-ok, lt),
  --ez-dev-unconfigured-tx: map-get($ez-dev-unconfigured,  text),
  --ez-dev-unconfigured-dk: map-get($ez-dev-unconfigured, dk),
  --ez-dev-unconfigured-lt: map-get($ez-dev-unconfigured, lt),
  --ez-dev-unreachable-tx: map-get($ez-dev-unreachable,  text),
  --ez-dev-unreachable-dk: map-get($ez-dev-unreachable, dk),
  --ez-dev-unreachable-lt: map-get($ez-dev-unreachable, lt),
  --ez-dev-bad-config-tx: map-get($ez-dev-bad-config, text),
  --ez-dev-bad-config-dk: map-get($ez-dev-bad-config, dk),
  --ez-dev-bad-config-lt: map-get($ez-dev-bad-config, lt),
  --ez-dev-bad-credentials-tx: map-get($ez-dev-bad-credentials, text),
  --ez-dev-bad-credentials-dk: map-get($ez-dev-bad-credentials, dk),
  --ez-dev-bad-credentials-lt: map-get($ez-dev-bad-credentials, lt),
  --ez-dev-expired-license-tx: map-get($ez-dev-expired-license, text),
  --ez-dev-expired-license-dk: map-get($ez-dev-expired-license, dk),
  --ez-dev-expired-license-lt: map-get($ez-dev-expired-license, lt),
  --ez-dev-unanticipated-tx: map-get($ez-dev-unanticipated, text),
  --ez-dev-unanticipated-dk: map-get($ez-dev-unanticipated, dk),
  --ez-dev-unanticipated-lt: map-get($ez-dev-unanticipated, lt),
  --ez-dev-attempting-fallback-tx: map-get($ez-dev-attempting-fallback, text),
  --ez-dev-attempting-fallback-dk: map-get($ez-dev-attempting-fallback, dk),
  --ez-dev-attempting-fallback-lt: map-get($ez-dev-attempting-fallback, lt),
  --ez-dev-using-fallback-tx: map-get($ez-dev-using-fallback, text),
  --ez-dev-using-fallback-dk: map-get($ez-dev-using-fallback, dk),
  --ez-dev-using-fallback-lt: map-get($ez-dev-using-fallback, lt),
);

//
// Multi-variant CSS color variable map.
//
// This map defines colors for which multiple CSS variables should
// be generated, namely a core color plus nine scaled color variants.
//
// Each entry will produce ten color variables
// e.g. --base,
//      --base-100, --base-300, --base-300, --base-400,   # Darker shades
//      --base-500,                                       # Same as --base
//      --base-600, --base-700, --base-800, --base-900    # Lighter shades
//
$ez-color-vars-scaled: (
  --ez-body: $body-color,
  --ez-main-bg: $mainBackground,
  --ez-breadcrumb-link: $ez-breadcrumb-link-color,
  --ez-login-title: $loginTitleColor,
  --ez-login-text: $loginTextColor,
  --ez-personal-dark: $personalDarkened,
  --bs-primary: theme-color("primary"),
  --bs-secondary: theme-color("secondary"),
  --bs-success: $nomadix-primary-color,
  --bs-info: theme-color("info"),
  --bs-warning: theme-color("warning"),
  --bs-danger: theme-color("danger"),
  --bs-light: theme-color("light"),
  --bs-dark: theme-color("dark"),

  --bs-in-service: theme-color("in-service"),
  --bs-blocked: theme-color("blocked"),
  --bs-start-date-pending: theme-color("start-date-pending"),
  --bs-consent-pending: theme-color("consent-pending"),
  --bs-new-policy-avail: theme-color("new-policy-avail"),
  --bs-error-status: theme-color("error-status"),
  --bs-plan-pending: theme-color("plan-pending"),
  --bs-paid-pending: theme-color("paid-pending"),
  --bs-expired-license: theme-color("expired-license"),
  --bs-unanticipated: theme-color("unanticipated"),
  --bs-expired: theme-color("expired"),

  --bs-dt-spinner-color: theme-color("dt-spinner-color"),

  --bs-dev-ok: theme-color("dev-ok"),
  --bs-dev-unconfigured: theme-color("dev-unconfigured"),
  --bs-dev-unreachable: theme-color("dev-unreachable"),
  --bs-dev-bad-credentials: theme-color("dev-bad-credentials"),
  --bs-dev-bad-config: theme-color("dev-bad-config"),
  --bs-dev-expired-license: theme-color("dev-expired-license"),
  --bs-dev-unanticipated: theme-color("dev-unanticipated"),
  --bs-dev-attempting-fallback: theme-color("dev-attempting-fallback"),
  --bs-dev-using-fallback: theme-color("dev-using-fallback"),
);

//
// Single-variant RGB-fragment CSS variable definitions.
//
// This will generate a set of CSS variables whose values are NOT legitimate CSS colors
// but are instead a sequence of discrete comma-separated RGB fragments that permit the
// variable to be used as an argument to CSS rgb() and rgba() function calls.
//
// The variable names will be suffixed with '-rgb' by the generator mixin.
//
// Each entry will produce one color variable
// e.g. --name-rgb: R,G,B;
//
// and can be referenced in CSS by:
// e.g. color: rgba(var(--name-rgb), 0.3);
//
$ez-color-vars-fragged: (
  --ez-dropdown-border: $dropdown-border-color,
  --bs-gray-500: $gray-500,

);

//
// Multi-variant RGB-fragment CSS variable definitions.
//
// Generates a series of color variables with RGB fragment values rather than
// usable color values.
//
// Each entry will produce ten color variables
// e.g. --base-rgb,
//      --base-100-rgb, --base-300-rgb, --base-300-rgb, --base-400-rgb, # Darker shades
//      --base-500-rgb,                                                 # Same as --base-rgb
//      --base-600-rgb, --base-700-rgb, --base-800-rgb, --base-900-rgb  # Lighter shades
//
$ez-color-vars-fragged-scaled: ();

//
// CSS Theming Variables Generator
//
//
// :root {
//   @include ez-color-vars($ez-color-vars-plain);
//   @include ez-color-vars($ez-color-vars-scaled, true);
//   @include ez-color-var-frags($ez-color-vars-fragged);
//   @include ez-color-var-frags($ez-color-vars-fragged-scaled, true);
// }

@mixin ez-generate-css-vars() {
  @include ez-color-vars($ez-color-vars-plain);
  @include ez-color-vars($ez-color-vars-scaled, true);
  @include ez-color-var-frags($ez-color-vars-fragged);
  @include ez-color-var-frags($ez-color-vars-fragged-scaled, true);
}
