@media screen and (max-width: 440px){
    .top-bar-text{
        small {
            i.material-icons{
                width: 4rem !important;
                font-size: 2.2rem !important;
            }
        }
    }
}

@media screen and (max-width: 767px){
    .login-content{
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .text-error-2{
        margin-left: 0;
    }
                

    .calp-content{
        .special-btn{
            margin-top: 1rem;
        }
        .calp-password{
            
            label{
                min-height: 2rem !important;
                margin: 0 !important;
                padding: 0 !important;
            }

            div{
                input{
                    margin-bottom: 0 !important;
                    height: 2.6rem;
                }
            }

            button{
                top: 0.8rem;
            }
        }
    }

    .ac-content{
        form{
            .form-group{
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                ul{
                    li{
                        margin-right: 1.5rem !important;
                        width: auto;
                        min-width: 7rem !important;
                        max-width: none !important;
                    }
                }
            }
            .ul-receive-sms{
                position: relative !important;
            }

            .ul-receive-sms-error{
                position: relative !important;
                top: 0 !important;
                p{
                    text-align: left;
                }
            }
        }

        .change-account-li-input{
            width: 100% !important;
            input{
                padding: 0.3rem 0.5rem !important;
                margin: 0.5rem 0;
                border: 0.1rem solid rgba(0, 0, 0, 0.3);
            }
        }
    }

    .wrapper .interior-content .caution{
        min-height: unset;
        margin: 0.5rem 0 !important;
    }

    .interior-content{
        .expandible-panel{
            .expandible-panel-content{
                form{
                    .accout-information-phone-container{
                        position: static !important;
                    }
                }
            }
            .ac-content{
                    ul{
                        margin: 0;
                        li{
                            display: inline-block;

                            &:first-of-type{
                                    max-width: unset !important;
                            }
                        }
                    }
                    .account-text-error-1{
                        li{
                            width: 100%;
                            p{
                                text-align: left;
                            }
                        }
                    }
            }
        }
    }


}
@media screen and (min-width: 768px){
    .account-information-phone{
        right: 0;
        position: absolute;
    }

    .caution{
        margin: 0 !important;
        margin-top: -0.5rem !important;
    }

    .calp-content{
        .calp-password{
            span{
                width: 9.5rem !important;
            }
        }
    }
}



/* SERVICES CSS */

.calp-content{
        .calp-password{
            @media screen and (max-width: 575px){
                flex-direction: column;
            }
        }
}
@media screen and (max-width: 440px){
    .panel-body{
        .container-fluid{
            .expandible-panel-content{
                ul{
                    li{
                        min-width: 5rem !important;
                    }
                }
            }
        }
    }
    .email-this-poilcy-container{
        flex-direction: column;
        align-items: center;
        button{
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            min-width: 15rem;
        }
    }
}

@media screen and (max-width: 575px){
    .navbar{
        padding: 0.5rem 1rem 2rem 1rem;
        .navbar-header{
            width: 100%;
        }
        .navbar-resident-address{
            bottom: 1.5rem;
        }
    }
    #residentTable_wrapper{
        tr{
            th:last-of-type, td:last-of-type{
                display: none !important;
            }
        }
    }
    .add-new-resident-form{
        .expandible-panel-content{
            .second-col{
                margin-top: 1rem;
            }
        }
    }
    .new-resident-options{
        flex-direction: column !important; 
        .base-button{
            width: 100% !important;
            max-width: none !important;
            text-align: left;
        }
    }
    .navbar-collapse{
        position: fixed;
        z-index: 6;
        width: 100%;
        height: 100% !important;
        top: 0.7rem;
        left: 0;
        background: rgb(242, 242, 242);
        ul{
            background: white;
            border: 0.1rem solid rgba(0,0,0,0.1);
            padding-left: 1rem;
        }
    }
    .li-navbar-icons{
        a{
            display: none !important;
        }
    }
    
    .navbar{
       
        #mainNavbarContent {
            ul{
                li{
                    a{
                        display: block;
                        margin: 0.8rem 0 0.2rem 1.5rem;
                        &:first-of-type {
                            margin: 0 !important;
                        }
                    }
                }
            } 
        }
    }
    .reset-wifi-word-ul{
        li{
        
            label{
                p{
                    width: 29%;
                    
                }
                span, input{
                    width: 69%;
                }
            }
        }
    }

    #mainNavbarContent{
        ul{
            li{
                display: block !important;
                text-align: left;
                &:last-of-type{
                    padding-left: 0.5rem !important;
                    span{
                        p{
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
     .services-options{
        flex-direction: column;
        .services-options-box{
            padding: 0 !important;
            width: 100%;
            .services-options-element-btn{
                max-width: 25rem;
            }
            .services-options-element-1{
                label{
                    span{
                        width: 15%;
                    }
                    select{
                        width: 85%;
                        margin-left: 3rem;
                    }
                }
            }
        }
        
    }
}
@media screen and (min-width: 768px){
    .services-options{
        .services-options-box{
            .services-options-element-1{
                label{
                    select{
                        min-width: 26rem !important;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 400px){
    .navbar{
        padding: 0.5rem 1rem 4rem 1rem;
        i.material-icons{
            font-size: 2.5rem !important;
            padding-right: 3rem;
        }
    }
}
@media screen and (max-width: 684px){
.unit-container{
    .expandible-panel-content{
        ul{
            li{
                label{
                    width: 39%;
                }
                input, select, span{
                    width: 59%;
                }
                .unit-a101-checkboxes{
                    label{
                        padding-left: 40%;
                        
                    }
                }
            }
        }

    }
}
@media screen and (max-width: 640px){
    .dataTables_filter{
        display: inline-block;
        float: right !important;
        top: 0;    
    }
    .table-title h2{
        bottom: -4.5rem;
    }
}
.resident-settings-text-error{
    margin-left:40%;  
}
}
