//
// Nomadix EzWifi SCSS variable definitions partial
//
// This SCSS partial is the primary source of theming EzWifi.
// Its purpose is to provide a definitive series of EzWifi SCSS
// variables for use elsewhere for actual CSS generation.
//
//

// @import "dish-branding";
// @import "blueport-branding";
// @import "spectrum-branding";
// @import "safetynet-branding";

// --------------------------------------------
// ------ SOURCED FROM CUSTOM CREATIVES -------
@import "legacy-variables";

// --------------------------------------------------------------
// --- Font Sizing
//
// As a matter of policy EzWifi CSS should provide a base font
// size in HTML elements of 10px, to be scaled thereafter in rem.
//
// Thus 1.4rem would correspond to 14px, and so on and so forth.
//
// We use a percentage equivalent here that is chosen to yield
// a size of 10px from browser default 16px. This use of percentage
// means that EzWifi will scale to match any user-made changes
// to browser preferences.
// --------------------------------------------------------------
$ez-html-font-size: 62.5% !default;
$ez-body-font-size: 1.4rem !default;
$ez-font-size-small: 1.2rem !default;

// --------------------------------------------------------------
// --- Default theming colors
//
// --------------------------------------------------------------
$nomadix-primary-color: rgb(144, 192, 88);
$nomadix-secondary-color: rgb(28, 74, 113);
$nomadix-light-color: rgb(244, 245, 247);
$nomadix-dark-color: rgb(68, 69, 69);
$nomadix-muted-color: rgb(150, 154, 160);

$ez-standard-primary: rgb(110, 175, 67) !default;
$ez-standard-secondary: rgb(8, 83, 148) !default;
$ez-standard-logo: "logo-site-default.png" !default;

// $ez-primary-color: $nomadix-primary-color;
// $ez-secondary-color: $nomadix-secondary-color;

$ez-primary-color: $ez-standard-primary !default;
$ez-secondary-color: $ez-standard-secondary !default;
$ez-light-color: $nomadix-light-color !default;
$ez-dark-color: $nomadix-dark-color !default;
$ez-muted-color: $nomadix-muted-color !default;
$ez-warning-color: rgb(255, 109, 0) !default;
$ez-danger-color: rgb(187, 16, 16) !default;

$ez-brand-image-path: "../images/#{$ez-standard-logo}" !default;
$ez-brand-image: url($ez-brand-image-path);
$ez-brand-bg-size: 80px 80px !default;
$ez-brand-min-width: 80px !default;
$ez-brand-min-height: 80px !default;

$ez-bg-image: none !default;
$ez-bg-size: contain !default;
$ez-bg-repeat: no-repeat !default;
$ez-bg-position: center !default;
$ez-bg-origin: border-box !default;
$ez-bg-opacity: 0.6 !default;
$ez-bg-blend-mode: normal !default;
$ez-signin-bg-posn: absolute !default;
$ez-signin-bg-opacity: 1 !default;
$ez-bg-min-height: 0 !default;
$ez-content-min-height: 0 !default;

$ez-gamut-1: ( dk: rgb(101, 148, 127), lt: rgb(134, 199, 169), text: white, ) !default;
$ez-gamut-2: ( dk: rgb(122, 164, 186), lt: rgb(155, 213, 242), text: white, ) !default;
$ez-gamut-3: ( dk: rgb(238, 132, 40), lt: rgb(246, 193, 147), text: white, ) !default;
$ez-gamut-4: ( dk: rgba(238, 70, 40, 0.945), lt: rgb(243, 28, 12), text: white, ) !default;
$ez-gamut-5: ( dk: rgb(37, 5, 90), lt: rgb(57, 75, 180), text: white, ) !default;

// --------------------------------------------------------------
// --- Subscription Status Colors
//
// --------------------------------------------------------------
$ez-in-service:               ( dk: rgb(33, 37, 41), lt: $nomadix-primary-color, text: white, ) !default;
$ez-blocked:                  ( dk: rgb(220, 53, 69), lt: rgb(220, 53, 69), text: white, ) !default;
$ez-start-date-pending:       ( dk: $nomadix-secondary-color, lt: rgb(134, 197, 169), text: white, ) !default;
$ez-consent-pending:          ( dk: rgb(255, 109, 0), lt: rgb(255, 109, 0), text: white, ) !default;
$ez-new-policy-avail:         ( dk: rgb(23, 162, 184), lt: rgb(23, 162, 184), text: white, ) !default;
$ez-error-status:             ( dk: rgb(187, 16, 16), lt: rgb(187, 16, 16), text: white, ) !default;
$ez-plan-pending:             ( dk: rgb(255, 109, 0), lt: rgb(255, 109, 0), text: white, ) !default;
$ez-paid-pending:             ( dk: rgb(220, 53, 69), lt: rgb(220, 53, 69), text: white, ) !default;
$ez-expired-license:          ( dk: rgb(170, 12, 6), lt: rgb(170, 12, 6), text: white, ) !default;
$ez-unanticipated:            ( dk: rgb(170, 12, 6), lt: rgb(170, 12, 6), text: white, ) !default;
$ez-expired:                  ( dk: rgb(187, 16, 16), lt: rgb(187, 16, 16), text: white, ) !default;

// --------------------------------------------------------------
// --- Device Status Colors
//
// --------------------------------------------------------------
$ez-dev-ok:                   ( dk: rgb(39, 166, 69), lt: rgb(134, 199, 169), text: white, ) !default;
$ez-dev-unconfigured:         ( dk: rgb(255, 109, 0), lt: rgb(246, 193, 147), text: white, ) !default;
$ez-dev-unreachable:          ( dk: rgb(170, 12, 6), lt: rgb(220, 53, 69), text: white, ) !default;
$ez-dev-bad-credentials:      ( dk: rgb(10, 117, 240), lt: rgb(243, 28, 12), text: white, ) !default;
$ez-dev-bad-config:           ( dk: rgb(63, 4, 158), lt: rgb(57, 75, 180), text: white, ) !default;
$ez-dev-expired-license:      ( dk: rgb(170, 12, 6), lt: rgb(220, 53, 69), text: white, ) !default;
$ez-dev-unanticipated:        ( dk: rgb(170, 12, 6), lt: rgb(220, 53, 69), text: white, ) !default;
$ez-dev-attempting-fallback:  ( dk: rgb(170, 12, 6), lt: rgb(220, 53, 69), text: white, ) !default;
$ez-dev-using-fallback:       ( dk: rgb(39, 166, 69), lt: rgb(134, 199, 169), text: white, ) !default;
// --------------------------------------------------------------
// --- Spinner var
// Created a new var to not use --bs-start-date-pending
// --------------------------------------------------------------
$ez-dt-spinner-color:       ( dk: $nomadix-secondary-color, lt: rgb(134, 197, 169), text: white, ) !default;


// --------------------------------------------------------------
// --- CC color overrides
//
// --------------------------------------------------------------
$saveButtonBackground: $ez-primary-color;
$linkColor: $ez-primary-color;
$loginTitleColor: $ez-secondary-color;
$mainTitleColor: $ez-secondary-color;
$expandTitleColor: $ez-secondary-color;
$emColor: $ez-secondary-color;
$cautionTitleColor: $ez-warning-color;
$cautionTextBackground: $ez-warning-color;

$bootstrapPrimaryColor: rgb(0, 123, 255) !default;
$bootstrapSecondaryColor: rgb(108, 117, 125) !default;
$bootstrapSuccessColor: rgb(39, 166, 69) !default;
$bootstrapDangerColor: rgb(220, 53, 69) !default;
$bootstrapWarningColor: rgb(255, 193, 7) !default;
$bootstrapInfoColor: rgb(23, 162, 184) !default;
$bootstrapLightColor: rgb(248, 249, 250);
$bootstrapDarkColor: rgb(52, 58, 64);
$bootstrapMutedColor: rgb(108, 117, 125);

