//
// Nomadix SCSS pre-bootstrap SCSS adjustments
//
// This SCSS partial is imported by EzWifi prior to bootstrap SCSS.
// Its purpose is to THEME BOOTSTRAP for EzWifi as best it can by
// presetting bootstrap's own SCSS variables with values drawn from
// EzWifi variables.
//
// Bootstrap SCSS variables live at "node_modules/bootstrap/scss/variables",
// and it is worthwhile examining that file to make sense of this one,
//
// Some rudimentary notes on theming bootstrap here:
//
//      https://getbootstrap.com/docs/4.1/getting-started/theming/#css-variables
//
//
@import "node_modules/bootstrap/scss/functions";

$theme-colors: (
  "primary": $ez-primary-color,
  "secondary": $ez-secondary-color,
  "success": $nomadix-primary-color,
  "danger": $ez-danger-color,
  "warning": $ez-warning-color,
  // "info": $nomadix-muted-color,
  // "light": $nomadix-light-color,
  // "dark": $nomadix-dark-color,

  "in-service": map-get($ez-in-service, dk),
  "blocked": map-get($ez-blocked, dk),
  "start-date-pending": map-get($ez-start-date-pending, lt),
  "consent-pending": map-get($ez-consent-pending, dk),
  "new-policy-avail": map-get($ez-new-policy-avail, dk),
  "error-status": map-get($ez-error-status, dk),
  "plan-pending": map-get($ez-plan-pending, dk),
  "paid-pending": map-get($ez-paid-pending, dk),
  "expired-license": map-get($ez-expired-license, dk),
  "unanticipated": map-get($ez-unanticipated, dk),
  "expired": map-get($ez-expired, dk),

  "dt-spinner-color": map-get($ez-dt-spinner-color, lt),

  "dev-ok": map-get($ez-dev-ok, dk),
  "dev-unconfigured": map-get($ez-dev-unconfigured, dk),
  "dev-unreachable": map-get($ez-dev-unreachable, dk),
  "dev-bad-credentials": map-get($ez-dev-bad-credentials, dk),
  "dev-bad-config": map-get($ez-dev-bad-config, dk),
  "dev-expired-license": map-get($ez-dev-expired-license, dk),
  "dev-unanticipated": map-get($ez-dev-unanticipated, dk),
  "dev-attempting-fallback": map-get($ez-dev-unanticipated, dk),
  "dev-using-fallback": map-get($ez-dev-ok, dk),

);

$ez-link-color: $ez-secondary-color !default;
$ez-breadcrumb-link-color: $ez-link-color !default;
$ez-label-color: #212529 !default;
$ez-placeholder-color: map-get($theme-colors, "secondary") !default;

html {
  font-size: $ez-html-font-size;
}

$font-size-base: $ez-body-font-size;
$link-color: $ez-link-color;
$input-color: rgb(0, 0, 16);
$enable-shadows: false;

$border-radius-lg: 0.7rem;
$custom-control-spacer-x: 1.25rem;
$custom-control-indicator-size: 1.5rem;
$checkmark-svg-ctrl: "class='checkmark' width='52' height='52' viewBox='0 0 52 52'";
$checkmark-circle: "<circle class='checkmark__circle' cx='26' cy='26' r='25' fill='none'/>";
$checkmark-tick: "<path class='checkmark__check' fill='#fff' d='M14.1 27.2l7.1 7.2 16.7-16.8'/>";
$x-custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' #{$checkmark-svg-ctrl}>#{$checkmark-tick}</svg>");

th,
td {
  vertical-align: top;
}
