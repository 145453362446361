// --------------------------------------------
// -------------- GENERAL COLORS --------------
$mainBackground: #f2f2f2 !default;
$saveButtonColor: #ffffff !default;
$saveButtonBackground: #6eaf43 !default;
$saveButtonTextSize: 1.4rem !default;
// $errorColor: #fe0000 !default;
$errorColor: rgb(187, 16, 16) !default;

$htmlRemSize: 10px !default;

// --------------------------------------------
// ------------- LOGIN VARIABLES --------------
$loginBackground: #ffffff !default;
$loginTitleColor: #085394 !default;
$loginTextColor: #555555 !default;
$inputTextColor: #555555 !default;

// --------------------------------------------
// ------------- MODAL VARIABLES --------------
$warningTextSize: 1.4rem !important !default;
$modalButtonTextSize: 1.4rem !important !default;

// --------------------------------------------
// ------------ BOOTSTRAP COLORS --------------
$bootstrapPrimaryColor: #007bff !default;
$bootstrapSecondaryColor: #6c757d !default;
$bootstrapWarningColor: #ffc107 !default;
$bootstrapSuccessColor: #27a645 !default;
$bootstrapDangerColor: #dc3545 !default;
$bootstrapInfoColor: #17a2b8 !default;

// --------------------------------------------
// ------------- TOP BAR VARIABLES ------------
$topBarLogo: url("../images/logo-nomadix-cloud.svg") !default;
$topBarLogoSize: 8.1rem !default;
$topBarBackground: #ffffff !default;
$topBarTextColor: #9b9b9b !default;
$topBarFontSize: 1.4rem !default;

$topBarAddressTextColor: #9b9b9b !default;
$topBarAddressFontSize: 1.4rem !default;

$topBarBurgerColor: #085394 !default;

$topHelpIconColor: $bootstrapSecondaryColor !default;
$topLogoutIconColor: $bootstrapSecondaryColor !default;

$topHelpIconHoverColor: $bootstrapPrimaryColor !default;
$topLogoutIconHoverColor: $bootstrapPrimaryColor !default;

// --------------------------------------------
// ------------- INTERIOR VARIABLES -----------
$mainTitleColor: #085394 !default;
$expandTitleColor: #085394 !default;
$expandTopBackgroundColor: #f7f8f9 !important !default;
$textColor: #555555 !default;
$textSize: 1.4rem !important !default;
$buttonTextSize: 1.3rem !important !default;
$emColor: #085394 !default;
$linkColor: #6eaf43 !default;

// --------------------------------------------
// -------------- CAUTION VARIABLES -----------
$cautionTitleColor: #ff6d00 !default;
$cautionTitleBackground: #ffffff !default;
$cautionTextBackground: #ff6d00 !default;
$cautionTextColor: #ffffff !default;

// --------------------------------------------
// ------------- PERSONAL VARIABLES -----------
$personalBackground: #86c5a9 !default;
$personalColor: #ffffff !default;
$personalDarkened: #65947f !default;
$personalResetWifiButtonTextSize: 1.6rem !default;

// --------------------------------------------
// -------------- GUEST VARIABLES -------------
$guestBackground: #91dcd8 !default;
$guestColor: #ffffff !default;
$guestDarkened: #6da5a2 !default;
$guestResetWifiButtonTextSize: 1.6rem !default;

// --------------------------------------------
// ------- BUTTON PERSONAL WIFI SETTINGS ------
$PWS_buttonColor: $personalColor !default;
$PWS_buttonBackground: $personalBackground !default;
$PWS_buttonDarkenedBackground: $personalDarkened !default;
// $PWS_buttonColor: #fff
// $PWS_buttonBackground: $bootstrapInfoColor
// $PWS_buttonDarkenedBackground: $bootstrapInfoDarkenedColor

// --------------------------------------------
// ------- BUTTON GUEST WIFI SETTINGS ---------
$GWS_buttonColor: $guestColor !default;
$GWS_buttonBackground: $guestBackground !default;
$GWS_buttonDarkenedBackground: $guestDarkened !default;
