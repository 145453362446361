.fatboy {
  &.fb-1 {
    color: var(--ez-gamut-5-tx);
    background: var(--bs-primary);
    span {
      background: var(--bs-primary);
    }
  }
  &.fb-2 {
    color: var(--ez-gamut-2-tx);
    background: var(--bs-primary);
    span {
      background: var(--bs-primary);
    }
  }
  &.fb-3 {
    color: var(--ez-gamut-3-tx);
    background: var(--bs-primary);
    span {
      background: var(--bs-primary);
    }
  }
  &.fb-4 {
    color: var(--ez-gamut-4-tx);
    background: var(--bs-primary);
    span {
      background: var(--bs-primary);
    }
  }
  &.fb-5 {
    color: var(--ez-gamut-5-tx);
    background: var(--bs-primary);
    span {
      background: var(--bs-primary);
    }
  }
}

//Safari Kludge - Above css selector doesnt work in safari
.btn-primary {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  box-shadow: none !important;
}

.toggle.btn-outline-primary .toggle-handle {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}
