.account-settings-text-error-1{}
.account-settings-text-error-2{}
.extended-expandible-panel-content{

}
.account-login-password{
  border: 0.1rem solid black;
    padding: 1rem !important;
    margin-top: 2rem;
    h3.interior-main-title{
      top: -2rem;
      background: white;
      padding: 0 0.4rem;
      position: relative;
      display: inline-block;
    }
}
#accountSettingsForm .text-error{
  width: 80%;
    padding-left: 39%;
    text-align: left;
    margin: 0 !important;
}
#accountSettingsForm{
  .col-12{
    padding: 0 1.5rem !important;
  }
}

.navbar #mainNavbarContent{
  .dropdown-menu{
    right: 0;
      left: 0;
      text-align: left;
      min-width: 7rem;
      li{
            padding-left: 0.5rem !important;
      }
  }
}
#unit-a101-support-pin, #unit-a101-tel{
  margin-left: -0.4rem;
}
// #account-settings{
//  .calp-content .calp-password{
//    display: block !important;
//  }
// }
@media screen and (min-width: 575px){
  #accountSettingsForm .text-error{
    width: 100%;
      padding-left: 39%;
  }
  .account-settings-container .pass-minumum-char{
    padding-left: 16.8% !important;
    margin-left: 0;
  }
}
@media screen and (max-width: 575px){
  #accountSettingsForm{
    ul{
      margin: 0.5rem 0 !important;
    }
  }
}
@media screen and (min-width: 685px){
  #accountSettingsForm .text-error{
    padding-left: 29% !important;
  }
}
